// api.js
import axios from "axios";

const apiClient = axios.create({
  baseURL: 'http://120.26.117.241:8055/msn/',
  // baseURL: "http://127.0.0.1:8055/msn/",
  timeout: 60000,
  // 其他配置...
});

// 添加请求拦截器以设置headers
apiClient.interceptors.request.use(
  (config) => {
    // 尝试从localStorage中获取token
    const user = JSON.parse(localStorage.getItem("user"));
    const key = sessionStorage.getItem("key");
    // 如果token存在，则将其添加到headers中
    if (user) {
      config.headers.token = user.token;
    }
    if (key) {
      config.headers.key = key;
    }

    return config;
  },
  (error) => {
    // 处理请求错误
    return Promise.reject(error);
  }
);

export default {
  get(resource, params) {
    return apiClient.get(resource, { params });
  },
  post(resource, data) {
    return apiClient.post(resource, data);
  },
  put(resource, data) {
    return apiClient.put(resource, data);
  },
  delete(resource) {
    return apiClient.delete(resource);
  },
  postFile(resource, fileData) {
    let formData = new FormData();
    // 添加文件数据到 FormData
    formData.append('file', fileData);
    // 设置请求头，包括 Content-Type 为 multipart/form-data
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    // 发送 POST 请求，上传文件
    return apiClient.post(resource, formData, { headers });
  },
};
